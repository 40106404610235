import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { isWarehouseFacility, renderAppLinks } from '../../helpers/utils';
import { Text, useMpharmaI18n } from 'mpharma-i18n';
import { useMixpanel } from './Mixpanel/Provider';
import { styled } from '@mui/material/styles';
import { useLocalStorage } from 'react-use';
import { APPS } from '../../helpers/constants';

const PREFIX = 'MyBottomNavigation';
const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  selected: `${PREFIX}-selected`,
  noPrint: `${PREFIX}-noPrint`
};

const Root = styled(Paper)(({ theme }) => ({
  [`& .${classes.root}`]: {
    color: theme?.palette?.primary?.main
  },
  [`&.${classes.container}`]: {
    position: 'fixed',
    left: '0px',
    bottom: '0',
    padding: '0px',
    margin: '0px',
    height: '50px',
    width: '100%',
    zIndex: 2000
  },
  [`& .${classes.selected}`]: {
    color: 'red'
  },
  [`&.${classes.noPrint}`]: {
    '@media print': {
      display: 'none !important'
    }
  }
}));

export function MyBottomNavigation({ user, history }) {
  const mixpanel = useMixpanel();
  const { translateKey } = useMpharmaI18n();

  const [facility] = useLocalStorage('bloom:facility');
  const facilityDetails = facility?.entities?.facilities[user?.facility_id];
  const isWarehouse = isWarehouseFacility(facilityDetails);

  const filterApps = app => {
    return !(isWarehouse && app.appName === APPS.ORDER);
  };

  return (
    <Root className={classNames(classes.container, classes.noPrint)}>
      <BottomNavigation showLabels={true}>
        {renderAppLinks(user, history)
          .filter(filterApps)
          .map(({ Icon, handleClick, tooltip }) => (
            <BottomNavigationAction
              key={tooltip}
              value={<Text i18nKey={tooltip} />}
              classes={{ root: classes.root, selected: classes.selected }}
              icon={<Icon />}
              onClick={() => {
                mixpanel.track('Click Bloom App', { app_name: translateKey(tooltip, { lng: 'en' }) });
                handleClick();
              }}
              data-testid={`bottom-navigation-${tooltip}`}
            />
          ))}
      </BottomNavigation>
    </Root>
  );
}

BottomNavigation.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(MyBottomNavigation);
