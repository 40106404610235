import React, { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { sha256 } from 'js-sha256';
import { useLocalStorage } from 'react-use';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import HelpIcon from '@mui/icons-material/HelpOutline';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationIcon from '@mui/icons-material/Notifications';
import SettingIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  Avatar,
  CircularProgress,
  CssBaseline,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  ListItem,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { useOfflineStatus } from '../../hooks/useOfflineStatus';
import useStockUploadTracker from '../../hooks/useStockUploadTracker';
import { accountSettingsLinks, settingMenuLinks } from '../../helpers/links';
import { APPS, INTERCOM_SECRET, RESOURCE_TITLE } from '../../helpers/constants';
import {
  getCookie,
  getCurrentBloomVersion,
  isWarehouseFacility,
  renderAppLinks,
  showExpiryNotification,
  showMpharmaWrappedNotification,
  sortErrorsByType
} from '../../helpers/utils';
import { InProgressDrawer, ProgressCard } from './InProgressComponents';
import AccountSettingDialog from './AccountSettingDialog';
import BottomLinkListItem from './BottomLinkListItem';
import BottomNavigation from './BottomNavigation';
import InternetSign from './InternetSign/';
import MobileAccountDialog from './MobileAccountDialog';
import NotificationBadgeIcon from './NotificationBadgeIcon';
import SelectCountry from './SelectCountry';
import SettingsMenu from './SettingsMenu';
import UpdateAppDialog from './UpdateAppDialog';
import BloomVersionDialog from './BloomVersionDialog';
import logo from '../../images/logo.png';
import logoTag from '../../images/logo_tag.png';
import { ReactComponent as LostSaleIcon } from '../../images/POS-notifications.svg';
import { ReactComponent as UpdateIcon } from '../../images/UpdateIcon.svg';
import { ReactComponent as VersionIcon } from '../../images/VersionIcon.svg';
import { ReactComponent as FacilityInsights } from '../../images/FacilityInsightsV2.svg';
import { Text, useMpharmaI18n } from 'mpharma-i18n';
import LanguageSelector from './ChangeLanguage';
import { useMixpanel } from './Mixpanel/Provider';
import { clearOfflineLoginCount, getOfflineLoginAttempt } from '../../db/utils';
import { each, groupBy } from 'lodash';
import moment from 'moment';
import { logOut, redirectAuthLogin } from '../state/auth/reducers';
import { Link } from './Notification';
import OfflineLoginScreen from './OfflineLoginScreen';
import useSalesSyncStatusBroadcastChannel from '../../hooks/useSalesSyncStatusBroadcastChannel';
import useDownloadProgress from '../../hooks/useDownloadProgress';
import styled from 'styled-components';
import { ReactComponent as RightArrow } from '../../images/arrowRgiht.svg';
import { ReactComponent as ErrorIcon } from '../../images/StockTransferError.svg';
import { ReactComponent as SuccessIcon } from '../../images/StockTransferSuccess.svg';
import useBulkStockUploadNotifications from '../../hooks/useBulkStockUploadNotifications';
import { useHistory } from 'react-router-dom';
import Button from 'mpharma-components-library/lib/Button';
import StockTransferAlerts from './StockTransferAlerts';
import { classes, LayoutStyleWrapper } from '../styles';

function Layout({
  children,
  isAdmin,
  stockUpload,
  salesHistoryLink,
  salesLinkReqIntervalId,
  inventoryLevelDownloadLink,
  inventoryLevelDownloadLinkReqIntervalId,
  inventoryLevelDownloadObjectId,
  inventoryLevelDownloadBroadChanId,
  endInventoryLevelDownloadBroadChannnel,
  cancelInventoryLevelDownloadRequest,
  cancelSalesHistoryDownloadRequest,
  setOpenedNotificationDrawer,
  openedNotificationDrawer,
  salesHistoryBroadChanId,
  salesLocalStorageHistoryObjectId,
  unsyncedLostSaleCount,
  location,
  openExpiredProductsNotification,
  bootIntercomBroadCastChannelId,
  fileUploadsProgress,
  resourceDownloadWorker,
  showNotificationDrawer,
  setShowNotificationDrawer
}) {
  const history = useHistory();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAchorEl] = useState(null);
  const { online } = useOfflineStatus();
  const [open, setOpen] = useState({});
  const [selectedApp, setSelectedApp] = useState(null);
  const [{ openFormulary, openUpdateModal, openChangeLanguage, openBloomVersionModal }, setOpenModal] = useState({
    openSwitch: false,
    openFormulary: false,
    openUpdateModal: false,
    openChangeLanguage: false,
    openBloomVersionModal: false
  });
  const [arrowRef, setArrowRef] = useState(null);
  const [stockProgress, errorCount, isCompleted] = useStockUploadTracker(stockUpload);
  const { salesSyncInProgress, salesSyncCompleted, outboxReport } = useSalesSyncStatusBroadcastChannel();
  const [hasPartialSync, setHasPartialSync] = useState(false);
  const bloomVersion = getCurrentBloomVersion();
  const [facility] = useLocalStorage('bloom:facility');
  const [user] = useLocalStorage('bloom:user');
  const contractType = user && facility?.entities?.facilities[user?.facility_id]?.contract_type;
  const {
    inventoryDownloadProgress,
    patientsDownloadProgress,
    salesDownloadProgress,
    promosDownloadProgress,
    priceUpdateProgress
  } = useDownloadProgress(resourceDownloadWorker);

  const {
    bulkStockUploadNotifications,
    errorLoadingBulkNotifications,
    fetchBulkStockUpload
  } = useBulkStockUploadNotifications(10000);

  function activateNotificationIcon() {
    return (
      salesSyncInProgress ||
      hasPartialSync ||
      stockProgress > 0 ||
      inventoryDownloadProgress?.progress > 0 ||
      patientsDownloadProgress?.progress > 0 ||
      salesDownloadProgress?.progress > 0 ||
      unsyncedLostSaleCount > 0 ||
      (!isAdmin && priceUpdateProgress?.progress > 0) ||
      (openExpiredProductsNotification && showExpiryNotification(window.location.pathname)) ||
      (openExpiredProductsNotification && showExpiryNotification(window.location.pathname))
    );
  }

  function isfileUploadsProgressPageSwitched() {
    return (
      fileUploadsProgress.price.pageSwitched ||
      fileUploadsProgress.deliveryNote.pageSwitched ||
      fileUploadsProgress.stock.pageSwitched ||
      fileUploadsProgress.customProduct.pageSwitched
    );
  }

  const facilityObject = facility?.entities?.facilities[user?.facility_id];
  const facilityName = !user?.is_staff ? facilityObject?.name : 'mPharma Admin User';
  const isWarehouse = isWarehouseFacility(facilityObject);

  const bottomLink = [
    {
      icon: <VersionIcon />,
      tooltip: 'nav.version',
      handleClick: () => openModal('openBloomVersionModal')
    },
    ...(online && !isAdmin
      ? [
          {
            icon: <UpdateIcon data-testid="update-icon-svg" />,
            tooltip: 'home.updateApplication',
            handleClick: () => {
              openModal('openUpdateModal');
            }
          }
        ]
      : []),
    {
      icon: (
        <button aria-hidden="true" tabIndex={-1} className={classes.intercomLauncher}>
          <HelpIcon id="intercom_custom_launcher" />
        </button>
      ),
      tooltip: 'nav.help',
      handleClick: bootIntercom
    },
    {
      icon: activateNotificationIcon() ? (
        <NotificationBadgeIcon>
          <NotificationIcon />
        </NotificationBadgeIcon>
      ) : (
        <NotificationIcon />
      ),
      tooltip: 'home.notifications'
    },
    {
      icon: <SettingIcon />,
      tooltip: 'home.settings'
    },
    {
      icon: (
        <div style={{ height: 24, width: 24 }}>
          <InternetSign />
        </div>
      ),
      tooltip: `${online ? 'nav.bloomOnline' : 'nav.bloomOffline'}`
    },
    {
      icon: (
        <Avatar classes={{ colorDefault: classes.avatar, root: classes.avatarRoot }}>{`${user?.first_name[0] ||
          ''}${user?.last_name[0] || ''}`}</Avatar>
      ),
      tooltip: 'nav.userSettings'
    }
  ];

  const bloomApps = {
    labTest: 'home.lab',
    facilityInsights: 'home.facilityInsights',
    orders: 'home.orders',
    inventory: 'home.inventory',
    posPatient: 'home.pos',
    posSales: 'home.salesHistory',
    healthServices: 'home.healthServices',
    vdl: 'home.vdl',
    memberManagement: 'home.memberManagement',
    home: 'home.label'
  };

  useEffect(() => {
    if (location.includes('lab-test/requests')) {
      setSelectedApp(bloomApps.healthServices);
    } else if (location.includes('health-services')) {
      setSelectedApp(bloomApps.healthServices);
    } else if (location.includes('vdl')) {
      setSelectedApp(bloomApps.vdl);
    } else if (location.includes('facility-insights')) {
      setSelectedApp(bloomApps.facilityInsights);
    } else if (location.includes('orders')) {
      setSelectedApp(bloomApps.orders);
    } else if (location.includes('inventory')) {
      setSelectedApp(bloomApps.inventory);
    } else if (location.includes('patient') || location.includes('add-drug')) {
      setSelectedApp(bloomApps.posPatient);
    } else if (location.includes('sales')) {
      setSelectedApp(bloomApps.posSales);
    } else if (location.includes('member-management')) {
      setSelectedApp(bloomApps.memberManagement);
    } else {
      setSelectedApp(bloomApps.home);
    }
  }, [
    bloomApps.home,
    bloomApps.facilityInsights,
    bloomApps.inventory,
    bloomApps.healthServices,
    bloomApps.vdl,
    bloomApps.labTest,
    bloomApps.orders,
    bloomApps.posPatient,
    bloomApps.posSales,
    bloomApps.memberManagement,
    location
  ]);

  const mixpanel = useMixpanel();
  const { translateKey } = useMpharmaI18n();
  const { boot, show, shutdown } = useIntercom();
  const [configs] = useLocalStorage('bloom:configs');
  const [isOfflineLoggedOut, setIsOfflineLoggedOut] = useLocalStorage('bloom:offlineLoggedOut', false);

  const countries =
    configs &&
    Object.values(configs)
      ?.filter(item => !!item?.country?.alpha_2_code)
      ?.map(item => item?.country?.alpha_2_code);

  useEffect(() => {
    if (user) {
      const myfacility = facility?.entities?.facilities[user?.facility_id];
      // Tag user to ensure events are mapped to the user
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $name: user.first_name,
        $email: user.email,
        $user_role: user?.groups?.toString() || '',
        $facility_name: myfacility?.name || ''
      });
      mixpanel.register({
        user_role: user?.groups?.toString() || '',
        facility_name: myfacility?.name || ''
      });
    }
  }, [user, mixpanel, facility]);

  useEffect(() => {
    const redirected = localStorage.getItem('redirected');
    if (isOfflineLoggedOut) {
      if (online === false) {
        localStorage.setItem('redirected', 'no');
      } else {
        if (redirected === 'no') {
          redirectAuthLogin();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOfflineLoggedOut]);

  useEffect(() => {
    if (online === true) {
      async function uploadOfflineLoginEvents() {
        try {
          const offlineAttempt = await getOfflineLoginAttempt();
          if (offlineAttempt.length > 0) {
            const grouped = groupBy(offlineAttempt, dt => moment(dt.date).week());
            for (const [week, data] of Object.entries(grouped)) {
              let states = ['success', 'fail'];
              let sums = {};
              each(data, function(item) {
                each(states, function(state) {
                  sums[state] = (sums[state] || 0) + item[state];
                });
              });
              const offlineTrackData = {
                week: week,
                ...sums
              };
              mixpanel.track('Offline Login', offlineTrackData);
            }
            clearOfflineLoginCount();
          }
        } catch (e) {
          console.log('Error updating offline login count', e);
        }
      }

      uploadOfflineLoginEvents();
    }
  }, [online, mixpanel]);

  useEffect(() => {
    if (user) {
      const facilityObject = facility?.entities?.facilities[user?.facility_id];
      const facilityName = !user?.is_staff ? facilityObject?.name : 'mPharma Admin User';
      const userHash = sha256.hmac(INTERCOM_SECRET, String(user.id));

      const extraIntercomFields = {};
      let userFacilityOwnerFields = {};

      if (user.facility_id) {
        let companyName = `${facilityName || ''} | ${user.location || ''}`;
        extraIntercomFields.company = { companyId: user.facility_id, name: companyName };

        if (facilityObject?.facility_owner_contact) {
          const companyOwnerFields = {
            owner_name: facilityObject?.facility_owner_contact?.full_name,
            owner_email: facilityObject?.facility_owner_contact?.email
          };

          extraIntercomFields.company = {
            ...extraIntercomFields.company,
            customAttributes: {
              ...companyOwnerFields
            }
          };
        }
      }
      if (facilityObject?.facility_owner_contact) {
        userFacilityOwnerFields = {
          is_facility_owner: parseInt(facilityObject?.facility_owner_contact?.user_id) === user.id,
          facility_owner_name: facilityObject?.facility_owner_contact?.full_name,
          facility_owner_email: facilityObject?.facility_owner_contact?.email
        };
      }

      boot({
        userHash,
        name: `${user.first_name} ${user.last_name}`,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        userId: String(user.id),
        ...extraIntercomFields,
        customAttributes: {
          facility_id: user.facility_id,
          user_location: user.location,
          facility: facilityName,
          contract_type: contractType,
          ...userFacilityOwnerFields
        }
      });
    }
  }, [boot, configs, user, facility, contractType]);

  useEffect(() => {
    if (bootIntercomBroadCastChannelId) {
      bootIntercom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bootIntercomBroadCastChannelId]);

  function handleHomeClick() {
    history.push('/');
  }

  const handleRefCreation = e => {
    setArrowRef(e);
  };

  function handleClick(type) {
    return function(e) {
      if (type === 'home.notifications') {
        endInventoryLevelDownloadBroadChannnel(null);
        setOpenedNotificationDrawer(true);
      }
      setAchorEl(e.currentTarget);
      setOpen({ [type]: true });
    };
  }

  function openModal(type) {
    setOpen({});
    setOpenModal({ [type]: true });
  }

  function closeModal(type) {
    setOpenModal({ [type]: false });
  }

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  function handleRequestClose() {
    setOpen({});
    setAchorEl(null);
    setShowNotificationDrawer(false);
  }

  function handleRoute(link) {
    setOpen({});
    history.push(`/account/${link}`);
  }

  function bootIntercom() {
    show();
  }

  function handleLogOut() {
    const idToken = getCookie('idToken');
    localStorage.setItem('bloom:cookie', idToken);
    localStorage.setItem('bloom:currentUserFacilityId', user.facility_id);
    shutdown();
    if (!online) {
      setIsOfflineLoggedOut(true);
    } else {
      logOut();
    }
  }

  const handleOutboxReportData = () => {
    if (outboxReport?.done) {
      const { failures, total } = outboxReport;
      if (total !== 0) {
        if (failures > 0 && failures !== total) {
          setHasPartialSync(true);
        }
      }
    }
  };
  useEffect(() => {
    if (salesSyncCompleted) {
      handleOutboxReportData();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [salesSyncCompleted, outboxReport?.done]);

  useEffect(() => {
    if (showNotificationDrawer) {
      setOpen({ 'home.notifications': true });
    }
  }, [showNotificationDrawer]);

  const notificationProgress = [
    {
      id: 'inventory-download',
      type: 'download',
      progress: inventoryDownloadProgress?.progress,
      success: inventoryDownloadProgress?.progress === 100,
      startDownloadTime: inventoryDownloadProgress?.start_time,
      showCard: !isAdmin,
      resourceTitle: RESOURCE_TITLE.INVENTORY
    },
    {
      id: 'price-update',
      type: 'download',
      progress: priceUpdateProgress?.progress,
      success: priceUpdateProgress?.progress === 100,
      showCard: !isAdmin,
      resourceTitle: RESOURCE_TITLE.PRICE
    },
    {
      id: 'patient-download',
      type: 'download',
      progress: patientsDownloadProgress?.progress,
      success: patientsDownloadProgress?.progress === 100,
      startDownloadTime: patientsDownloadProgress?.start_time,
      showCard: !isAdmin,
      resourceTitle: RESOURCE_TITLE.PATIENT_LIST
    },
    {
      id: 'sales-download',
      type: 'download',
      progress: salesDownloadProgress?.progress,
      success: salesDownloadProgress?.progress === 100,
      startDownloadTime: salesDownloadProgress?.start_time,
      showCard: !isAdmin,
      resourceTitle: RESOURCE_TITLE.SALES_HISTORY
    },
    {
      id: 'promos-download',
      type: 'download',
      progress: promosDownloadProgress?.progress,
      success: promosDownloadProgress?.progress === 100,
      startDownloadTime: promosDownloadProgress?.start_time,
      showCard: !isAdmin,
      resourceTitle: 'Promotions and Discounts'
    },
    {
      id: 'stock-upload',
      type: 'upload',
      progress: stockProgress,
      success: isCompleted,
      showCard: isAdmin,
      errorCount,
      resourceTitle: RESOURCE_TITLE.INVENTORY
    },
    {
      id: 'sales-history-download',
      type: 'sales',
      progress: 1,
      success: false,
      showCard: true,
      errorCount,
      resourceTitle: RESOURCE_TITLE.SALES
    },
    {
      id: 'inventory-level-download',
      type: 'inventoryLevelDownload',
      progress: 1,
      success: false,
      showCard: true,
      errorCount,
      resourceTitle: RESOURCE_TITLE.SALES
    },
    {
      id: 'price-upload-processing',
      type: 'fileUploadProcessing',
      progress: fileUploadsProgress.price.progress,
      processingStatus: fileUploadsProgress.price.processingStatus,
      success: fileUploadsProgress.price.progress === 100,
      showCard: isAdmin,
      errorCount: fileUploadsProgress.price.errorCount,
      resourceTitle: RESOURCE_TITLE.PRICE_LIST
    },
    {
      id: 'delivery-note-upload-processing',
      type: 'fileUploadProcessing',
      progress: fileUploadsProgress.deliveryNote.progress,
      success: fileUploadsProgress.deliveryNote.progress === 100,
      errorCount: fileUploadsProgress.deliveryNote.errorCount,
      showCard: isAdmin,
      resourceTitle: RESOURCE_TITLE.DELIVERY_NOTE
    },
    {
      id: 'stock-upload-processing',
      type: 'fileUploadProcessing',
      progress: fileUploadsProgress.stock.progress,
      success: fileUploadsProgress.stock.progress === 100,
      errorCount: fileUploadsProgress.stock.errorCount,
      showCard: isAdmin,
      resourceTitle: RESOURCE_TITLE.STOCK_COUNT
    },
    {
      id: 'custom-product-upload-processing',
      type: 'fileUploadProcessing',
      progress: fileUploadsProgress.customProduct.progress,
      success: fileUploadsProgress.customProduct.progress === 100,
      errorCount: fileUploadsProgress.customProduct.errorCount,
      showCard: isAdmin,
      resourceTitle: RESOURCE_TITLE.CUSTOM_PRODUCT
    },
    {
      id: 'sales-sync',
      type: salesSyncInProgress ? 'salesSync' : null,
      showCard: !isAdmin
    },
    {
      id: 'sales-sync-partial',
      type: hasPartialSync ? 'salesSyncPartial' : null,
      showCard: !isAdmin
    }
  ];

  function getErrorDetailTitle(errorType) {
    switch (errorType) {
      case 'VALIDATION_ERROR':
        return <Text i18nKey="cs.uploadError.validatingFile.description">Missing required column headers</Text>;
      case 'MISSING_REQUIRED_FIELDS':
        return <Text i18nKey="cs.uploadError.missingRequiredFields">Some products are missing required values</Text>;
      case 'EXCEEDED_AVALIABLE_STOCK':
        return (
          <Text i18nKey="cs.uploadError.exceededAvailableStock">
            Quantity for transfer of some products exceed available stock in Bloom
          </Text>
        );
      case 'EMPTY_FILE':
        return <Text i18nKey="cs.uploadError.emptyFile">File provided is empty</Text>;
      case 'NOT_IN_FACILITY_INVENTORY':
        return (
          <>
            Some <span style={{ fontFamily: 'Sofia Pro Bold' }}>“Drug IDs”</span>{' '}
            <Text i18nKey="cs.uploadError.notInFacilityInventory">did not match warehouse inventory in Bloom</Text>
          </>
        );
      default:
        return <Text i18nKey="cs.uploadError.default">Resolve the issues below</Text>;
    }
  }

  const appLinks = renderAppLinks(user, history, contractType).filter(({ tooltip, appName }) => {
    const isMemberDirectory = tooltip === 'home.memberDirectory';
    const isRestrictedOrder = isWarehouse && appName === APPS.ORDER;

    return !isMemberDirectory && !isRestrictedOrder;
  });

  const navItems = (
    <List className={classes.container} data-testid="layout-nav-items">
      <div>
        <ListItem
          tabIndex={0}
          aria-label={translateKey('home.label')}
          role="button"
          onClick={handleHomeClick}
          className={`${classes.menuItem} ${selectedApp === bloomApps.home && classes.selected}`}
          style={{ paddingTop: '11px', paddingBottom: '11px' }}
        >
          <ListItemIcon>
            <Tooltip placement="right" title={<Text i18nKey="home.label">Home</Text>}>
              <img width="30px" height="30px" src={logo} alt="mpharma_logo" />
            </Tooltip>
          </ListItemIcon>
        </ListItem>

        {appLinks.map(({ Icon, handleClick: onClickHandler, tooltip }) => (
          <MenuItem
            style={{ paddingTop: '11px', paddingBottom: '11px' }}
            key={tooltip}
            button
            onClick={() => {
              mixpanel.track('Click Bloom App', { app_name: translateKey(tooltip, { lng: 'en' }) });
              onClickHandler();
            }}
            selected={selectedApp === tooltip}
            className={`${selectedApp === tooltip && classes.selected}`}
            data-testid={`layout-nav-${tooltip}`}
          >
            <ListItemIcon>
              <Tooltip placement="right" title={<Text i18nKey={tooltip} />}>
                <Icon />
              </Tooltip>
            </ListItemIcon>
          </MenuItem>
        ))}
      </div>

      <LayoutStyleWrapper>
        {bottomLink.map(nav => {
          return (
            <BottomLinkListItem
              key={nav.tooltip}
              nav={nav}
              classes={classes}
              handleClick={handleClick}
              handleRefCreation={handleRefCreation}
              arrowRef={arrowRef}
              salesHistoryLink={salesHistoryLink}
              salesLinkReqIntervalId={salesLinkReqIntervalId}
              openedNotificationDrawer={openedNotificationDrawer}
              salesHistoryBroadChanId={salesHistoryBroadChanId}
              salesLocalStorageHistoryObjectId={salesLocalStorageHistoryObjectId}
              inventoryLevelDownloadLink={inventoryLevelDownloadLink}
              inventoryLevelDownloadLinkReqIntervalId={inventoryLevelDownloadLinkReqIntervalId}
              inventoryLevelDownloadObjectId={inventoryLevelDownloadObjectId}
              inventoryLevelDownloadBroadChanId={inventoryLevelDownloadBroadChanId}
              endInventoryLevelDownloadBroadChannnel={endInventoryLevelDownloadBroadChannnel}
              fileUploadsProgressPageSwitched={isfileUploadsProgressPageSwitched}
              fileUploadsProgress={fileUploadsProgress}
            />
          );
        })}
      </LayoutStyleWrapper>
    </List>
  );

  function handleOpenView(isError, isComplete, stockTransferId, StockTransferInventoryId) {
    handleRequestClose();
    history.push('/');
    isError
      ? window.open(
          `inventory/stock-transfer/${
            user.facility_id
          }/new/main?importCsvFile=${true}&stockTransferID=${stockTransferId}`,
          '_blank'
        )
      : isComplete
      ? window.open(`inventory/stock-transfer/${user.facility_id}?selectedRecord=${StockTransferInventoryId}`, '_blank')
      : window.location.replace(
          `inventory/stock-transfer/${
            user.facility_id
          }/new/main?importCsvFile=${true}&stockTransferID=${stockTransferId}`
        );
  }

  return isOfflineLoggedOut ? (
    <OfflineLoginScreen history={history} online={online} />
  ) : (
    <LayoutStyleWrapper className={classes.root}>
      <CssBaseline />
      {/** Mobile Drawer */}
      <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
        <AppBar position="fixed" color="default" className={classNames(classes.appBar, classes.noPrint)}>
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Typography color="inherit" noWrap>
              <img
                height="30px"
                src={logoTag}
                alt="mpharma_logo"
                onClick={() => history.push('/home')}
                className={classes.logo}
              />
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>

      <nav className={classes.drawer}>
        {/** Mobile Bottom Navigation */}
        <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
          <BottomNavigation user={user} />
          <Drawer
            className={classes.noPrint}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <MobileAccountDialog user={user} logOut={logOut} handleClick={handleClick} facilityName={facilityName} />
          </Drawer>
        </Hidden>

        {/** Desktop Side Navigation */}
        <Hidden only={['xs']} implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
              paperAnchorDockedLeft: classes.paperAnchorDockedLeft
            }}
            className={classes.noPrint}
            variant="permanent"
            open
          >
            {navItems}
          </Drawer>
        </Hidden>
      </nav>

      {/* Notifications Drawer  */}
      <Drawer onClose={handleRequestClose} open={open['home.notifications']}>
        <InProgressDrawer onClose={handleRequestClose}>
          {notificationProgress.map(item => {
            return (
              <ProgressCard
                key={item.id}
                id={item.id}
                type={item.type}
                progress={item.progress}
                processingStatus={item.processingStatus}
                showCard={item.showCard}
                success={item.success}
                startDownloadTime={item.startDownloadTime}
                resourceTitle={item.resourceTitle}
                salesHistoryLink={salesHistoryLink}
                salesLinkReqIntervalId={salesLinkReqIntervalId}
                cancelSalesHistoryDownloadRequest={cancelSalesHistoryDownloadRequest}
                cancelInventoryLevelDownloadRequest={cancelInventoryLevelDownloadRequest}
                openedNotificationDrawer={openedNotificationDrawer}
                setOpenedNotificationDrawer={setOpenedNotificationDrawer}
                inventoryLevelDownloadLink={inventoryLevelDownloadLink}
                inventoryLevelDownloadLinkReqIntervalId={inventoryLevelDownloadLinkReqIntervalId}
                processingErrorCount={item.type === 'fileUploadProcessing' && item.errorCount}
                closeNotificationDrawer={handleRequestClose}
              />
            );
          })}
        </InProgressDrawer>
        {showMpharmaWrappedNotification(user) && (
          <div className={classes.notificationContainer} style={{ flexWrap: 'wrap' }}>
            <FacilityInsights style={{ width: 40 }} className={classes.icon} />
            <div>
              <p className={classes.salesNotice}>
                <Text i18nKey="notifications.mPharmaWrapped.notification">
                  Reflect on the past year with #mPharmaWrapped
                </Text>
              </p>
              <Link href="/facility-insights/reports?i=wrapped">
                <Text i18nKey="notifications.mPharmaWrapped.action">Get started</Text>
              </Link>
            </div>
          </div>
        )}
        {unsyncedLostSaleCount && unsyncedLostSaleCount > 0 && (
          <div className={classes.notificationContainer}>
            <LostSaleIcon className={classes.icon} />
            <p className={classes.salesNotice}>
              {`You have ${unsyncedLostSaleCount} unsynced lost sale${unsyncedLostSaleCount === 1 ? '' : 's'}`}
            </p>
          </div>
        )}

        {errorLoadingBulkNotifications ? (
          <BulkUploadErrorContainer>
            <p> An error occured whilst loading bulk stock upload</p>
            <Button onClick={() => fetchBulkStockUpload()}>Try again</Button>
          </BulkUploadErrorContainer>
        ) : (
          bulkStockUploadNotifications?.map(
            ({ destination_facility_name, process_status, file_upload, transfer_request_id, id }) => {
              const isComplete = process_status === 'PROCESSING_COMPLETE';
              const isError = process_status === 'FAILED_VALIDATION';
              const isProcessing = process_status === 'PROCESSING_DATA';

              const errorDetails = sortErrorsByType(file_upload?.errors);

              return (
                <StockTransferItemContainer terminal={true}>
                  {isError && (
                    <ErrorContainer>
                      <ErrorIcon />
                    </ErrorContainer>
                  )}

                  {isComplete && (
                    <div style={{ marginRight: '16px' }}>
                      <SuccessIcon />
                    </div>
                  )}

                  <div style={{ width: '100%' }}>
                    <StockTransferItemTitle>Stock transfer upload in progress</StockTransferItemTitle>
                    <StockTransferItemFacilityName>{destination_facility_name}</StockTransferItemFacilityName>

                    {isComplete ? (
                      <SuccessMessage>
                        Successfully initiated the transfer of {file_upload?.num_of_records_processed_successfully}{' '}
                        products{' '}
                      </SuccessMessage>
                    ) : isError ? (
                      Object.entries(errorDetails).map(([errorType, errors], index) => (
                        <ErrorPillContainer inDrawer key={index}>
                          <ErrorPill>Error</ErrorPill> {getErrorDetailTitle(errorType)}
                        </ErrorPillContainer>
                      ))
                    ) : isProcessing ? (
                      <p style={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size={16} classes={{ colorPrimary: classes.primary }} />
                        <ValidatingFile>Processing stock...</ValidatingFile>
                      </p>
                    ) : (
                      <p style={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size={16} classes={{ colorPrimary: classes.primary }} />
                        <ValidatingFile>Validating file...</ValidatingFile>
                      </p>
                    )}

                    <ViewContainer onClick={() => handleOpenView(isError, isComplete, id, transfer_request_id)}>
                      <View>View</View> <RightArrow />
                    </ViewContainer>
                  </div>
                </StockTransferItemContainer>
              );
            }
          )
        )}
      </Drawer>

      {/* Settings Drawer  */}
      <Drawer anchor="left" onClose={handleRequestClose} open={open['home.settings']}>
        <SettingsMenu
          onClose={handleRequestClose}
          links={settingMenuLinks(history, isAdmin, user, openModal, setOpen)}
          handleClick={handleClick}
        />
      </Drawer>

      {/* Account Drawer  */}
      <Drawer tabIndex="-1" onClose={handleRequestClose}>
        <AccountSettingDialog onClose={handleRequestClose} handleClick={handleRoute} links={accountSettingsLinks} />
      </Drawer>

      {/* User settings menu  */}
      <Menu
        onClose={handleRequestClose}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(open['nav.userSettings'])}
        data-testid=""
      >
        <MenuItem disabled onClick={null}>{`${user?.first_name} ${user?.last_name}`}</MenuItem>
        <MenuItem onClick={handleLogOut}>
          <Text i18nKey="nav.signout">Sign out</Text>
        </MenuItem>
      </Menu>
      <Dialog
        classes={{ paper: classes.selectDropdownOption }}
        maxWidth={false}
        open={Boolean(openFormulary)}
        onClose={() => closeModal('openSwitch')}
      >
        <SelectCountry setOpenModal={setOpenModal} history={history} handleClose={closeModal} countries={countries} />
      </Dialog>
      <Dialog
        classes={{ paper: classes.selectDropdownOption }}
        maxWidth={false}
        open={Boolean(openUpdateModal)}
        onClose={() => closeModal('openUpdateModal')}
      >
        <UpdateAppDialog setOpenModal={openUpdateModal} handleClose={closeModal} />
      </Dialog>
      <Dialog
        maxWidth={false}
        open={Boolean(openBloomVersionModal)}
        onClose={() => closeModal('openBloomVersionModal')}
      >
        <BloomVersionDialog
          setOpenModal={openBloomVersionModal}
          handleClose={closeModal}
          bloomVersions={bloomVersion}
        />
      </Dialog>
      {openChangeLanguage && <LanguageSelector open={openChangeLanguage} setOpen={setOpenModal} />}
      <main className={classes.content}>
        {children}
        {/*Stock transfer alerts*/}
        <StockTransferAlerts
          user={user}
          notifications={bulkStockUploadNotifications}
          openNotificationsDrawer={() => setOpen({ 'home.notifications': true })}
          handleOpenView={handleOpenView}
        />
      </main>
    </LayoutStyleWrapper>
  );
}

const StockTransferItemContainer = styled.div`
  padding: 8px 26px;
  border-bottom: 1px solid #e7e8ea;
  margin-top: 12px;

  ${props =>
    props.terminal && {
      display: 'flex',
      alignItems: 'center'
    }}
`;

const StockTransferItemTitle = styled.h2`
  color: #262626;
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
  margin-bottom: 8px;
`;

const StockTransferItemFacilityName = styled.p`
  border-radius: 4px;
  background: #dfe5ea;
  padding: 4px;
  color: #0a0f18;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  width: fit-content;
`;

const ValidatingFile = styled.p`
  color: #393f4d;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  margin: 16px 8px;
`;

const View = styled.a`
  color: #587cc8;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  margin-right: 4px;
`;

const ViewContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const ErrorContainer = styled.div`
  width: 40px;
  height: 40px;
  background: #fae6e6;
  padding: 10px;
  border-radius: 50%;
  margin-right: 16px;
`;

const ErrorPillContainer = styled.p`
  background-color: #fef3f2;
  border-radius: 16px;
  color: #b42318;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.2px;
  padding: 4px 10px 4px 0;
  width: fit-content;
  margin: 20px 0;
`;

const ErrorPill = styled.span`
  background-color: #d92d20;
  color: #fff;
  border-radius: 16px;
  padding: 2px 8px;
`;

const SuccessMessage = styled.p`
  color: #393f4d;
  font-family: Sofia Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  margin: 16px auto;
`;

const BulkUploadErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

Layout.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.any,
  roles: PropTypes.array,
  history: PropTypes.object,
  contractType: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired
};

export default Layout;
