import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import OfflineNotification from './OfflineNotification';
import SyncDataNotifications from './SyncDataNotifications';
import useOfflineStatus from '../../../hooks/useOfflineStatus';
import UnavailableService from './UnavailableService';
import ExpiredProductsNotification from './ExpiredProductsNotification';
import ProductsExpiringSoonNotification from './ProductsExpiringSoonNotification';
import PricingNotification from './PricingNotification';
import MCAFeedbackNotification from './MCAFeedbackNotification';
import WrappedNotification from './WrappedNotification';
import FacilityInsightsReminderNotification from './FacilityInsightsReminderNotification';
import QuantityNotification from './QuantityNotification';
import StockTransfersPendingCompletionNotification from './StockTransfersPendingCompletionNotification';

const BloomNotifications = ({
  showFIReminder,
  updateNextFIReminderDate,
  setshowFIReminder,
  showWrappedNotification,
  hideWrappedNotification,
  newMCAFeedback,
  setShowMCAFeedbackDialog,
  setNewMCAFeedback,
  showMCAFeedbackDialog,
  setOpenExpiredProductsNotification,
  openExpiredProductsNotification,
  openExpiringSoonNotification,
  setOpenExpiringSoonNotification,
  resourceDownloadWorker,
  setOpenPriceUpdateModal,
  setShowNotificationDrawer,
  setOpenedNotificationDrawer
}) => {
  const history = useHistory();
  const { online } = useOfflineStatus();
  const [user] = useLocalStorage('bloom:user');
  const [facility] = useLocalStorage('bloom:facility');

  const facilityObject = facility && user && facility.entities && facility.entities.facilities[user.facility_id];

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '35px',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        overflowY: 'auto',
        zIndex: '1199'
      }}
    >
      <StockTransfersPendingCompletionNotification user={user} />
      <FacilityInsightsReminderNotification
        user={user}
        showFIReminder={showFIReminder}
        updateNextFIReminderDate={updateNextFIReminderDate}
        setshowFIReminder={setshowFIReminder}
      />
      <WrappedNotification
        user={user}
        showWrappedNotification={showWrappedNotification}
        hideWrappedNotification={hideWrappedNotification}
      />
      <MCAFeedbackNotification
        user={user}
        newMCAFeedback={newMCAFeedback}
        setShowMCAFeedbackDialog={setShowMCAFeedbackDialog}
        setNewMCAFeedback={setNewMCAFeedback}
        showMCAFeedbackDialog={showMCAFeedbackDialog}
      />
      <UnavailableService service="inventory" online={online} facilityObject={facilityObject} />
      <SyncDataNotifications history={history} setShowNotificationDrawer={setShowNotificationDrawer} />
      <ExpiredProductsNotification
        user={user}
        setOpenExpiredProductsNotification={setOpenExpiredProductsNotification}
        openExpiredProductsNotification={openExpiredProductsNotification}
      />
      <ProductsExpiringSoonNotification
        user={user}
        openExpiringSoonNotification={openExpiringSoonNotification}
        setOpenExpiringSoonNotification={setOpenExpiringSoonNotification}
      />
      <PricingNotification
        user={user}
        resourceDownloadWorker={resourceDownloadWorker}
        setOpenPriceUpdateModal={setOpenPriceUpdateModal}
        setOpenedNotificationDrawer={setOpenedNotificationDrawer}
      />
      <QuantityNotification user={user} />
      <OfflineNotification facilityObject={facilityObject} />
    </div>
  );
};

export default BloomNotifications;
