import React, { useEffect, useState } from 'react';
import useOfflineStatus from '../../../hooks/useOfflineStatus.js';
import { get } from '../../../admin/api/index.js';
import { API_ROOT } from '../../../helpers/constants';
import Notification from '../Notification.js';
import { Text } from 'mpharma-i18n';
import { ReactComponent as CompleteStockTransferIcon } from '../../../images/confirm_stock_transfer_icon.svg';
import Button from 'mpharma-components-library/lib/Button/index.js';
import { useHistory } from 'react-router-dom';
import { differenceInDays, parseISO } from 'date-fns';

const MAX_ALLOWED_DAYS = 1;

const StockTransfersPendingCompletionNotification = ({ user }) => {
  const { online } = useOfflineStatus();
  const history = useHistory();
  const [showPendingStockTransfersNotification, setShowPendingStockTransfersNotification] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    //Make this request every time MCA is on the homepage
    //The inventory module has a banner alert to be make the MCA aware of pending transfers
    if (window.location.pathname === '/') getApprovedStockTransfers(user?.facility_id);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, user, window.location.pathname]);

  async function getApprovedStockTransfers(facilityId) {
    const approvedStockTransfers = await get(
      `${API_ROOT}/inventory-bff/stock-transfers/?source_or_destination_facility=${facilityId}&page=1&status=I`
    );

    //Find if current facility is the receiving facility for any approved stock
    const hasPendingStockTransfers = approvedStockTransfers?.results?.length > 0;

    //Check to see if facility has pending stock transfer within grace period
    approvedStockTransfers?.results?.find(item => {
      const parsedStartDate = parseISO(item?.updated_at);
      const today = new Date();

      const daysDifference = differenceInDays(today, parsedStartDate);
      const isWithinGracePeriod = daysDifference <= MAX_ALLOWED_DAYS;

      if (isWithinGracePeriod) setDaysLeft(MAX_ALLOWED_DAYS - daysDifference);

      return isWithinGracePeriod;
    });

    if (hasPendingStockTransfers) {
      setShowPendingStockTransfersNotification(true);
    }

    //If at any time there are no approved transfers hide the notification and remove the last check date
    if (!hasPendingStockTransfers && approvedStockTransfers?.results?.length === 0) {
      setShowPendingStockTransfersNotification(false);
    }
  }

  function handleNavigateToStockTransfersPage() {
    history.push(`/inventory/stock-transfer/${user?.facility_id}/?status=I`);
  }

  return (
    <>
      {!user?.is_staff &&
        showPendingStockTransfersNotification &&
        !window.location.pathname.includes('inventory/all') &&
        !window.location.pathname.includes('inventory/stock-transfer') && (
          <Notification
            warning_light
            className="warning_light"
            style={{ width: '510px' }}
            action={
              <Button
                variant="secondary"
                text={<Text i18nKey="home.viewTransfer"></Text>}
                onClick={handleNavigateToStockTransfersPage}
                style={{ padding: '8px', fontSize: '12px', fontFamily: 'Sofia Pro Bold', marginTop: '6px' }}
              />
            }
            message={
              <>
                <b style={{ fontFamily: 'Sofia Pro Bold' }}>
                  <Text i18nKey="home.completeIncomingTransfer">Complete Incoming Stock Transfer</Text>
                </b>
                <br />
                {daysLeft <= 0 ? (
                  <Text i18nKey="home.autoCompleteIncomingTransferMsg" variables={{ daysLeft }}>
                    <p style={{ margin: 0, padding: 0, fontSize: '13px' }}>
                      You have pending stock transfers that will be completed automatically by your facility PSA if not
                      completed.
                    </p>
                  </Text>
                ) : (
                  <Text i18nKey="home.completeIncomingTransferMsg" variables={{ daysLeft }}>
                    <p style={{ margin: 0, padding: 0, fontSize: '13px' }}>
                      You have pending stock transfers. Complete it within 1 day to ensure accurate inventory levels, or
                      it will be finalised by your PSA.
                    </p>
                  </Text>
                )}
              </>
            }
            icon={<CompleteStockTransferIcon style={{ margin: 2 }} />}
            handleClose={() => setShowPendingStockTransfersNotification(false)}
          />
        )}
    </>
  );
};

export default StockTransfersPendingCompletionNotification;
