import React from 'react';

import { Text } from 'mpharma-i18n';
import { ReactComponent as BillsBankingAndTaxes } from '../images/BillsBankingAndTaxes.svg';
import { ReactComponent as DevicesAndEquipment } from '../images/DevicesAndEquipment.svg';
import { ReactComponent as LearningCenter } from '../images/LearningCenter.svg';
import { ReactComponent as Products } from '../images/Products.svg';
import { ReactComponent as Renovations } from '../images/Renovations.svg';

export const BASE_NAME = process.env.REACT_APP_BASE_NAME;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const BASE_URL = `${API_ROOT}/cs-bff`;
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const APP_URL = process.env.REACT_APP_API_ROOT;
export const LOGISTICS_APP_URL = process.env.REACT_APP_LOGISTICS_APP_URL;
export const APP_RELEASE = `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`;
export const APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const APP_VERSION = `v${process.env.REACT_APP_VERSION}`;

export const DRAWER_WIDTH = 56;

export const APP_ENV = process.env.REACT_APP_ENV;
export const OOP_BFF_BASE = APP_ENV === 'staging' ? process.env.REACT_APP_BFF_URL : `${API_ROOT}/oop-bff`;
export const INVENTORY_BFF_URL = API_ROOT + '/inventory-bff';
export const INVENTORY_OPERATIONS_TRACKING_URL = INVENTORY_BFF_URL + '/track-operations';

export const links = [{ url: 'users/userinfo' }, { url: 'oop-bff/country-config/' }];

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const INTERCOM_SECRET = process.env.REACT_APP_INTERCOM_SECRET;

export const INVENTORY_OPERATION_TYPES = {
  inventory: {
    download: 'ID'
  },
  sales: {
    download: 'SD'
  },
  patients: {
    download: 'PD'
  },
  batches: {
    download: 'BD'
  },
  promos: {
    download: '0D'
  }
};

export const EXPIRING_SOON_MAX_NUMBER_OF_DAYS = 90;
export const EXPIRING_SOON_MIN_NUMBER_OF_DAYS = 1;

export const ORDERS_MODULE_COUNTRY_CONFIGS = {
  //add new countries configs
  GH: {
    useMarketPlace: true
  },
  NG: {
    useMarketPlace: true
  },
  GA: {
    useMarketPlace: true
  },
  KE: {
    useMarketPlace: true
  },
  ZM: {
    useMarketPlace: false
  },
  RW: {
    useMarketPlace: false
  },
  NW: {
    useMarketPlace: false
  },
  ET: {
    useMarketPlace: false
  },
  UG: {
    useMarketPlace: false
  },
  SN: {
    useMarketPlace: false
  },
  TG: {
    useMarketPlace: false
  },
  MW: {
    useMarketPlace: false
  },
  BJ: {
    useMarketPlace: true
  },
  TZ: {
    useMarketPlace: false
  }
};

export const BROADCAST_CHANNEL_NAMES = {
  PRICE_UPLOAD_PROGRESS_CHANNEL: 'price-upload-progress-channel',
  DELIVERY_NOTE_UPLOAD_PROGRESS_CHANNEL: 'delivery-note-upload-progress-channel',
  STOCK_UPLOAD_PROGRESS_CHANNEL: 'stock-upload-progress-channel',
  CUSTOM_PRODUCT_UPLOAD_PROGRESS_CHANNEL: 'custom-product-upload-progress-channel',
  BOOT_INTERCOM_CHANNEL: 'boot-intercom-channel',
  SALES_SYNC_STATUS_BROADCAST_CHANNEL: 'sales-sync-channel'
};

export const FILE_UPLOADS_STATUS = {
  STARTED: 'started',
  IN_PROGRESS: 'inprogress',
  COMPLETED: 'completed'
};

export const COUNTRIES_WITH_ENABLED_FACILITY_MANAGEMENT = ['GA', 'ET'];

export const BLOOM_GUIDE_LINKS = [
  {
    title: <Text>Bills, Banking, & Taxes</Text>,
    link: 'https://mpharma.com',
    icon: <BillsBankingAndTaxes />
  },
  {
    title: <Text>Products</Text>,
    link: 'https://mpharma.com',
    icon: <Products />
  },
  {
    title: <Text>Devices & Equipment</Text>,
    link: 'https://mpharma.com',
    icon: <DevicesAndEquipment />
  },
  {
    title: <Text>Renovations</Text>,
    link: 'https://mpharma.com',
    icon: <Renovations />
  },
  {
    title: <Text>Learning center</Text>,
    link: 'https://mpharma.com',
    icon: <LearningCenter />
  }
];

export const ONBOARDING_ACTIVITES_REFERENCE = {
  created_facility: { title: 'Create facility', link: null },
  added_users_to_facility: {
    title: 'Assign users to facility',
    link: 'inventory/settings/facilities/facility-onboarding/add-user'
  },
  added_users_as_contact: {
    title: 'Assign users to facility contacts',
    link: 'inventory/settings/facilities/facility-onboarding/contacts'
  },
  entered_contract_detail: {
    title: 'Enter contract details',
    link: 'inventory/settings/facilities/facility-onboarding/contract'
  },
  uploaded_stock_count: {
    title: 'Upload stock count document',
    link: 'inventory/settings/facilities/facility-onboarding/stock-and-delivery'
  },
  uploaded_delivery_note: {
    title: 'Upload delivery note document',
    link: 'inventory/settings/facilities/facility-onboarding/stock-and-delivery'
  },
  entered_physical_address: {
    title: 'Entered physical address',
    link: 'inventory/settings/facilities/facility-onboarding/address'
  },
  uploaded_price_list: {
    title: 'Upload price list document  ',
    link: 'inventory/settings/facilities/facility-onboarding/price-list'
  },
  launched_facility: {
    title: 'Launch facility',
    link: 'inventory/settings/facilities/facility-onboarding/launch'
  }
};

export const IMPORTANT_DATES_REFERENCE = {
  initial_contract_date: 'Initial contract date',
  expected_go_live_date: 'Expected go-live date',
  expected_pay_back_date: 'Expected payback date',
  refurbishment_completion_date: 'Refurbishment completion date',
  contract_expiration_date: 'Contract expiration date'
};
export const GROUPED_UPDATING_REASONS_OPTIONS = [
  {
    label: <Text i18nKey="home.updateApplication.reasons.pricing">Update pricing</Text>,
    value: 'Update pricing'
  },
  {
    label: <Text i18nKey="home.updateApplication.reasons.products">Update product list</Text>,
    value: 'Update product list'
  },
  {
    label: <Text i18nKey="home.updateApplication.reasons.see.pricing">Can't see product pricing</Text>,
    value: "Can't see product pricing"
  },
  {
    label: <Text i18nKey="home.updateApplication.reasons.find.products">Can't find products</Text>,
    value: "Can't find products"
  },
  {
    label: <Text i18nKey="home.updateApplication.reasons.bloom.unresponsive">Bloom not being responsive</Text>,
    value: 'Bloom not being responsive'
  },
  {
    label: <Text i18nKey="home.updateApplication.reasons.others">Others</Text>,
    value: 'Others'
  }
];

export const DATABASE_NAMES = {
  BLOOM: 'bloom',
  OUTBOX: 'outbox'
};
export const PERMISSION_TO_CREATE_PROMOS = 'promo.create';

export const MY_ACTIVITIES = 'myactivities';
export const BLOOM_TOOLS = 'bloomtools';

export const FACILITY_TIME_ZONE_UTC_OFFSETS = {
  GH: 0,
  ET: -180,
  UG: -180,
  RW: -120,
  NG: -60,
  GA: -60,
  TG: 0,
  KE: -180,
  ZM: -120,
  SN: 0,
  MW: -120
};

export const PRICE_UPLOAD_PROCESSING_STATUS = {
  VALIDATING_FILE: 'VALIDATING_FILE',
  VALIDATING_PRICES: 'VALIDATING_PRICES',
  FAILED_VALIDATION: 'FAILED_VALIDATION',
  RETRIEVING_FACILITY_DETAILS: 'RETRIEVING_FACILITY_DETAILS',
  PROCESSING_PRICES: 'PROCESSING_PRICES',
  COMPLETED: 'COMPLETED',
  QUEUED_UPLOAD: 'QUEUED_UPLOAD'
};

export const CURRENT_PRICE_UPLOAD_COUNTRY = 'current-price-upload-country';
export const CURRENT_PRICE_UPLOAD_FACILITY = 'current-price-upload-facility';

export const RESOURCE_TITLE = {
  INVENTORY: 'Inventory',
  PRICE: 'Price',
  PATIENT_LIST: 'Patient list',
  SALES_HISTORY: 'Sales history',
  PROMOTIONS_AND_DISCOUNTS: 'Promotions and Discounts',
  SALES: 'Sales',
  PRICE_LIST: 'Price list',
  DELIVERY_NOTE: 'Delivery Note',
  STOCK_COUNT: 'Stock count',
  CUSTOM_PRODUCT: 'Custom Product'
};

export const SALES_SYNC_PARTIAL = 'salesSyncPartial';
export const SALES_SYNC = 'salesSync';

export const FACILITY_TYPES = {
  HOSPITAL: 'hospital',
  PHARMACY: 'pharmacy',
  WAREHOUSE: 'warehouse'
};

export const APPS = {
  POS: 'POS',
  SALES_HISTORY: 'Sales History',
  ANALYTICS: 'Analytics',
  INVENTORY: 'Inventory',
  ORDER: 'Order',
  MUTTI_MEMBER_MANAGEMENT: 'Mutti Member Management',
  MUTTI_MEMBER_DIRECTORY: 'Mutti Member Directory',
  HEALTH_SERVICES: 'Health Services',
  WAREHOUSE_LOGISTICS: 'Warehouse & Logistic'
};
